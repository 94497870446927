import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container, Button } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TopicList from "../components/topicList"
import YouTubePlayer from "../components/youtubeplayer"
import CheckText from "../components/textwithcheck"
import SimpleReactLightbox from "simple-react-lightbox"
import FaQ from "../components/FaQ"
import Aos from "aos"
import PortableText from "react-portable-text"
import { SRLWrapper } from "simple-react-lightbox"

const OboMne = ({ data }) => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  const {
    header,
    header2,
    thumbnail,
    title,
    _rawBody,
    workExperience,
    _rawYoutubeBlock,
    _rawEducation,
    education,
  } = data.sanityOboMne
  const picture = getImage(thumbnail.asset)
  const serializer = {
    strong: props => <strong className="text-white" {...props} />,
  }
  const options = {
    settings: {
      autoplaySpeed: 1000,
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 1000,
      lightboxTransitionSpeed: 0.6,
      lightboxTransitionTimingFunction: "linear",
      overlayColor: "rgba(0, 0, 0, 0.9)",
      slideSpringValues: [300, 200],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: "linear",
    },
    buttons: {
      backgroundColor: "rgba(30,30,36,0.8)",
      iconColor: "rgba(255, 255, 255, 0.8)",
      iconPadding: "5px",
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: "40px",
    },
    caption: {
      captionAlignment: "start",
      captionColor: "#FFFFFF",
      captionContainerPadding: "10",
      captionFontFamily: "inherit",
      captionFontSize: "inherit",
      captionFontStyle: "inherit",
      captionFontWeight: "inherit",
      captionTextTransform: "inherit",
      showCaption: true,
    },
    translations: {
      closeText: "Закрыть",
      nextText: "Вперед",
      previousText: "Назад",
    },
  }
  return (
    <Layout>
      <Seo
        title={title}
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="xl">
        <Row>
          {/* photo and text block */}
          <Col
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="py-5 arrowbackground-1"
          >
            <Container fluid="lg">
              <Row className="uk-heading-divider">
                <Col xl={4} lg={4} md={3} sm={2} xs={12} className="pb-3">
                  <figure>
                    <GatsbyImage
                      image={picture}
                      data-aos="fade-in"
                      className="rounded"
                      alt={thumbnail.attribution || "Степаненко Александр"}
                    />
                    {thumbnail.capton !== null && (
                      <figcaption className="text-white pt-2">
                        {thumbnail.caption}
                      </figcaption>
                    )}
                  </figure>
                </Col>
                <Col xl={8} lg={8} md={9} sm={10} xs={12}>
                  <div className="text-white" data-aos="fade-in">
                    <h1 className="display-6 uk-heading-divider">
                      {header}
                      <br />
                      <span style={{ fontWeight: "200" }}>{header2}</span>
                    </h1>
                    <div>
                      <PortableText
                        className="uk-text-meta"
                        content={_rawBody}
                        serializers={serializer}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p
                    style={{ textTransform: "uppercase" }}
                    className="uk-heading-bullet pt-3 text-light display-6"
                    data-aos="fade-right"
                  >
                    {workExperience}
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* /* basic topics */}
          <Col xl={4} lg={4} md={12} sm={12} xs={12} className="p-5 bg-primary">
            <TopicList />
            <FaQ />
          </Col>
        </Row>
      </Container>
      <div style={{ width: "100%" }} className="bg-secondary">
        <Container fluid="lg">
          <Row>
            <Col
              xl={5}
              lg={5}
              md={12}
              sm={12}
              xs={12}
              className="text-left youtubepic bg-primary"
            >
              <div className="p-5 text-white" data-aos="fade-in">
                <PortableText
                  className="uk-text-meta"
                  content={_rawYoutubeBlock.body}
                  serializers={serializer}
                />
                <p className="pt-5">
                  <Button
                    href="https://www.youtube.com/channel/UCbqsgs5JK2Kpv9mgY89Ni_g/"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="info"
                  >
                    {_rawYoutubeBlock.youtubeTitle}
                  </Button>
                </p>
              </div>
            </Col>
            <Col
              xl={7}
              lg={7}
              md={12}
              sm={12}
              xs={12}
              className="bg-primary p-0"
            >
              <YouTubePlayer url={_rawYoutubeBlock.youtubeLink} />
            </Col>
          </Row>
        </Container>
      </div>

      <CheckText text={_rawEducation.educationHeader} />

      <div
        style={{ width: "100%" }}
        className="bg-secondary uk-card-default border-bottom border-primary"
      >
        <Container fluid="xl">
          <Row>
            <Col xl={8} lg={8} md={12} sm={12} xs={12} className="p-sm-5 p-3">
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div className="portfolio-lightbox" data-aos="fade-in">
                    {education.gallery.images.map(image => (
                      <GatsbyImage
                        key={image._key}
                        image={image.asset.gatsbyImageData}
                        className="diplomas-thumbs"
                        alt={image.attribution}
                      />
                    ))}
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
            </Col>
            <Col
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="p-5 bg-primary"
            >
              <div>
                <PortableText
                  className="uk-text-meta"
                  content={_rawEducation.educationList}
                  serializers={serializer}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityOboMne {
      _rawBody(resolveReferences: { maxDepth: 10 })
      workExperience
      _rawEducation(resolveReferences: { maxDepth: 10 })
      _rawYoutubeBlock(resolveReferences: { maxDepth: 10 })
      header
      header2
      id
      title
      thumbnail {
        asset {
          gatsbyImageData
        }
        attribution
        caption
      }
      education {
        gallery {
          images {
            asset {
              gatsbyImageData
            }
            attribution
            caption
            _key
          }
        }
      }
    }
  }
`

export default OboMne
